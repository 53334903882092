<template>
  <div class="trade-page">
    <div class="general-box container-fluid">
      <div class="row">
        <div class=" col-xl-9 ps-0">
          <div class="left-wrap-col row">
            <TradeGraphicPanel />
            <div class="body">
              <Wallet />
            </div>
          </div>
        </div>
        <div class=" col-xl-3 mt-lg-0 mt-4 mx-0 px-0">
          <div class="right-wrap-col row">
            <TradePairs />
            <BalanceHistoryPanel />
            <BotTerminal />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TradePairs from './TradePairs.vue'
import Wallet from './Wallet.vue'
import TradeGraphicPanel from './TradeGraphicPanel.vue'
import BotTerminal from './BotTerminal.vue'
import BalanceHistoryPanel from './BalanceHistoryPanel.vue'

const DEF_VIEW_TF = '30m'

export default {
  components: {
    TradePairs,
    Wallet,
    TradeGraphicPanel,
    BotTerminal,
    BalanceHistoryPanel,
  },
}
</script>

<style lang="scss" src="./styles/src.scss"></style>
