<template>
  <div class="balance-history-panel">
    <div class="header">
      {{ $t('trate_page.balance_history.title') }}
    </div>
    <div class="body">
      <div class="tabs-history">
        <div
          class="item"
          v-for="(item, key) in tabs"
          :key="key"
          :class="{ active: key == activeTab }"
          @click="activeTab = key"
        >
          {{ item }}
        </div>
      </div>

      <table v-if="activeTab == tabs.statistic">
        <thead>
          <tr>
            <td></td>
            <td>{{ $t('trate_page.balance_history.change') }}</td>
            <td>{{ $t('trate_page.balance_history.balance') }}</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in list" :key="i">
            <td>
              {{ item.name }}
            </td>
            <td>
              <div
                v-if="item.percent"
                class="profit"
                :class="{
                  green: item.percent > 0,
                  red: item.percent < 0,
                }"
              >
                {{ item.percent }}%
              </div>
            </td>
            <td>
              <div class="balance">
                {{ item.balance | currency }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="tab-history" v-if="activeTab == tabs.history">
        <table>
          <thead>
            <tr>
              <td>{{ $t('trate_page.balance_history.balance') }}</td>
              <td>{{ $t('trate_page.balance_history.date') }}</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in listBalancesComputed" :key="i">
              <td>{{ item.balance | currency }}</td>
              <td>{{ item.date | luxon('d-MM-yyyy') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

function calculatePercent(a, b) {
  let res = Math.abs(((b - a) / a) * 100).toFixed(2)

  if (isNaN(res) || res == Infinity) return undefined
  return res
}

export default {
  data: () => ({
    activeTab: 'statistic',
    listBalances: [],
  }),

  computed: {
    ...mapState('Finance', ['balanceStats']),

    listBalancesComputed() {
      console.log(this.listBalances.slice(1))
      return [
        {
          balance: this.balanceStats?.now || 0,
          date: new Date().toISOString(),
        },
        ...this.listBalances.slice(1),
      ]
    },

    tabs() {
      return {
        statistic: 'statistic',
        history: 'history',
      }
    },

    list() {
      if (!this.balanceStats) return []

      return [
        {
          name: '24 hours',
          percent: calculatePercent(
            this.balanceStats.yesterday,
            this.balanceStats.now
          ),
          balance: this.balanceStats.now,
        },
        {
          name: '7 days',
          percent: calculatePercent(
            this.balanceStats.lastWeek,
            this.balanceStats.yesterday
          ),
          balance: this.balanceStats.lastWeek,
        },
        {
          name: '30 days',
          percent: calculatePercent(
            this.balanceStats.lastMonth,
            this.balanceStats.yesterday
          ),
          balance: this.balanceStats.lastMonth,
        },
      ]
    },
  },

  created() {
    this.getBalanceStats()

    this.getAppBalanceWithPagination({ limit: 30, sort: { date: -1 } }).then(
      data => {
        this.listBalances = data.docs
      }
    )
  },

  methods: {
    ...mapActions('Finance', [
      'getBalanceStats',
      'getAppBalanceWithPagination',
    ]),
  },
}
</script>
